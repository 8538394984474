<!-- @format -->

<template>
	<LayoutDefaultNew>
		<sub-navbar page="Admin" />
		<div v-if="loading">Loading .......</div>
		<div v-if="!loading">
			<div style="font-size: large; font-weight: 700; color: red">
				Total: {{ total }} kr.
			</div>
			<b-field class="margin-top-20" label="Select beginning date">
				<b-datepicker
					v-model="selectedBeginningDate"
					:show-week-number="showWeekNumber"
					:locale="locale"
					:first-day-of-week="firstDayOfWeek"
					placeholder="Click to select..."
					icon="calendar-today"
					trap-focus
				>
				</b-datepicker>
			</b-field>
			<b-field label="Select ending date">
				<b-datepicker
					v-model="selectedEndingDate"
					:show-week-number="showWeekNumber"
					:locale="locale"
					:first-day-of-week="firstDayOfWeek"
					placeholder="Click to select..."
					icon="calendar-today"
					trap-focus
				>
				</b-datepicker>
			</b-field>
		</div>
		<div class="margin-bottom-30 margin-top-10">
			<vue-good-table
				id="dispatches"
				:columns="columns"
				:rows="rows"
				styleClass="vgt-table striped bordered"
				theme="black-rhino"
				max-height="700px"
				:fixed-header="true"
				:pagination-options="{
					enabled: true,
					mode: 'records',
					perPage: 10,
					position: 'bottom',
					perPageDropdown: [5, 10, 15, 20],
					dropdownAllowAll: true,
					setCurrentPage: 1,
					jumpFirstOrLast: true,
					firstLabel: 'First',
					lastLabel: 'Last',
					nextLabel: 'next',
					prevLabel: 'prev',
					rowsPerPageLabel: 'Rows per page',
					ofLabel: 'of',
					pageLabel: 'page', // for 'pages' mode
					allLabel: 'All',
				}"
			>
				<template slot="table-row" slot-scope="props">
					<span v-if="props.column.field === 'preview'">
						<b-button class="is-small is-info">Preview</b-button>
					</span>
					<span v-else-if="props.column.field === 'toPayFormatted'">
						<p class="text-bold text-red text-align-right">
							{{ props.row.toPayFormatted }}
						</p>
					</span>
					<span v-else>
						{{ props.formattedRow[props.column.field] }}
					</span>
				</template>
			</vue-good-table>
			<download-csv :data="rows">
				<div class="margin-bottom-30 margin-top-30">
					<b-button class="is-success"> Download the List </b-button>
				</div>
			</download-csv>
		</div>
	</LayoutDefaultNew>
</template>
<script>
import GetTimeIntervalInvoicesToBePaid from '@/_srcv2/pages/admin/invoices-to-be-paid/graphql/GetTimeIntervalInvoicesToBePaid.graphql'
import { useQuery } from '@vue/apollo-composable'
import { reactive, ref, watchEffect } from '@vue/composition-api'
import Store from '@/store'
import dateformat from 'dateformat'
import numeral from 'numeral'
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar'
export default {
	name: 'InvoicesToBePaid',
	components: {
		SubNavbar,
	},
	setup() {
		const parameters = reactive({
			beginningDate: '',
			endingDate: '',
		})
		const today = ref(new Date())
		const fetchDates = () => {
			return new Promise((resolve, reject) => {
				if (today.value !== null) {
					selectedBeginningDate.value = today.value
					selectedEndingDate.value = today.value
					resolve('ok')
				} else {
					const reason = new Error('Today is null')
					reject(reason)
				}
			})
		}
		const selectedBeginningDate = ref(new Date())
		watchEffect(() => {
			parameters.beginningDate = dateformat(
				selectedBeginningDate.value,
				'yyyy-mm-dd',
			)
		})
		const selectedEndingDate = ref(new Date())
		watchEffect(() => {
			parameters.endingDate = dateformat(selectedEndingDate.value, 'yyyy-mm-dd')
		})
		const showWeekNumber = false
		const locale = 'sv-SE'
		const firstDayOfWeek = 1
		fetchDates()
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { onResult, loading } = useQuery(
			GetTimeIntervalInvoicesToBePaid,
			() => ({
				our_company: Store.getters.getUserCurrentCompany,
				bd: parameters.beginningDate,
				ed: parameters.endingDate,
			}),
			options,
		)
		const rows = ref([])
		onResult((result) => {
			console.log('result: ', result.data.document_transactions)
			rows.value = result.data.document_transactions.map((item) => {
				const payment =
					item.invoice_payments_aggregate?.aggregate?.sum?.payment_credit /
						100 || 0
				return {
					payment,
					invoiceNumber: item.invoice_number,
					customer: item._customer.customer_title,
					date: item.invoice_date,
					dueDate: item.invoice_due_date,
					toPay: Math.round(
						item._goods_transactions_aggregate.aggregate.sum
							.line_price_total_credit /
							100 +
							item._goods_transactions_aggregate.aggregate.sum.vat_credit /
								100 -
							payment,
					),
					toPayFormatted:
						numeral(
							Math.round(
								item._goods_transactions_aggregate.aggregate.sum
									.line_price_total_credit /
									100 +
									item._goods_transactions_aggregate.aggregate.sum.vat_credit /
										100 -
									payment,
							),
						).format('0,0.00') + ' kr.',
				}
			})
			console.log('rows: ', rows.value)
			total.value = numeral(
				rows.value.reduce(function (accumulator, item) {
					return accumulator + item.toPay
				}, 0),
			).format('0,0.00')
		})
		const total = ref(0)
		// * ----------------------------------------------------------------------
		const columns = [
			{
				label: 'Invoice Number',
				field: 'invoiceNumber',
				width: '110px',
			},
			{
				label: 'Customer',
				field: 'customer',
			},
			{
				label: 'Invoice Date',
				field: 'date',
				width: '125px',
			},
			{
				label: 'Invoice Due Date',
				field: 'dueDate',
				width: '125px',
			},
			{
				label: 'To Pay',
				field: 'toPayFormatted',
				width: '165px',
			},

			{
				label: 'Preview',
				field: 'preview',
				width: '50px',
				sortable: false,
			},
		]
		return {
			loading,
			locale,
			showWeekNumber,
			firstDayOfWeek,
			selectedBeginningDate,
			selectedEndingDate,
			total,
			rows,
			columns,
		}
	},
}
</script>
